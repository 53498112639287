export const reviewsData = [
  {
    title: "Effective app for truly learning German",
    text: "I am taking a German course, and this is the only app that has been recommended by the teacher. This app effectively replaces both Duolingo and Anki. It is the only app that focuses on both German grammar and vocabulary. It goes into great depth on grammar topics, just like a school book.",
    username: "mbt101",
    date: "Oct 8, 2024",
    rating: 5,
    country: "United Kingdom",
  },
  {
    title: "Great for learning German",
    text: "Perfect app for learning German words. Very good for learning German grammar with theory explanations and exercises.",
    username: "Puska19",
    date: "Oct 8, 2024",
    rating: 5,
    country: "Hungary",
  },
  {
    title: "Really nice app",
    text: "Very useful when you are struggling with the German language. I helps always having a fresh content.",
    username: "AhmedMoustafa92",
    date: "Oct 7, 2024",
    rating: 5,
    country: "Germany",
  },
  {
    title: "Favorite 👏🏼👏🏼👏🏼",
    text: "Very practical to practise German. Effective on the vocabulary and articles",
    username: "theunlimited'",
    date: "Oct 7, 2024",
    rating: 5,
    country: "Netherlands",
  },
  {
    title: "Good app that focusses on German grammar",
    text: "I am taking a German course. My German teacher recommended that we use this app because it is the only German learning app that focuses on grammar topics. I agree.",
    username: "Chris94ha",
    date: "Oct 6, 2024",
    rating: 5,
    country: "Germany",
  },
  {
    title: "Great App",
    text: "Superb for learning german, easy and for various difficulties!",
    username: "Hilda Cirule",
    date: "Oct 6, 2024",
    rating: 5,
    country: "Latvia",
  },
  {
    title: "Almanca öğrenmek için süper",
    text: "Özellikle quiz yapabildiğim için Almanca öğrenirken çok fayda sağladı.",
    username: "Melioda Nor",
    date: "Oct 6, 2024",
    rating: 5,
    country: "Türkiye",
  },
  {
    title: "Alternatiflerinden çok iyi",
    text: "Uzun zamandır Almanca öğrenmek için çalışmalar yapıyorum şuana kadar kullandığım uygulamalardan çok daha iyi, alternatifleri sadece her gün girmemizi sağlayarak kendi ceplerini dolduruyorlar. Almanca kelime ve gramer öğrenmek için harika bir uygulama.",
    username: "Can Elliot",
    date: "Oct 4, 2024",
    rating: 5,
    country: "Türkiye",
  },
  {
    title: "Simple way to learn",
    text: "Had fun learning German.",
    username: "Evivz",
    date: "Oct 2, 2024",
    rating: 5,
    country: "India",
  },
  {
    title: "독일어 학습 좋네요!!",
    text: "독일어 단어 학습과 재미있는 문법 연습을 위한 아주 좋은 앱입니다.",
    username: "SNFZO",
    date: "Sep 29, 2024",
    rating: 5,
    country: "Korea, Republic of",
  },
  {
    title: "Top",
    text: "Application très efficace pour apprendre les mots allemands avec les flashcards Anki. L'application propose de nombreux exercices sur de nombreux sujets de grammaire allemande.",
    username: "Swordse",
    date: "Sep 28, 2024",
    rating: 5,
    country: "France",
  },
  {
    title: "Exactly what I was looking for",
    text: "Good Anki flashcards for learning 15000 German words. The only app for learning German grammar just like in a school book.",
    username: "ItsBeacon",
    date: "Sep 28, 2024",
    rating: 5,
    country: "Germany",
  },
  {
    title: "Fantastic app for German words and grammar",
    text: "I have been using Duolingo, but at some stage, I couldn't further improve my German with it. Linguico is the only app that focuses on German grammar. The Anki flashcards for learning vocabulary are also the best I have seen.",
    username: "mario495",
    date: "Sep 28, 2024",
    rating: 5,
    country: "United States",
  },
  {
    title: "Practicing German just like in school",
    text: "This app works great for learning German vocabulary with Anki style flashcards and for practicing German grammar just like in a school.",
    username: "Naomindlander",
    date: "Sep 28, 2024",
    rating: 5,
    country: "United States",
  },
  {
    title: "Great apps for learning German language",
    text: "This app helps me a lot to learn about German language, thank you",
    username: "apipgaming21",
    date: "Sep 27, 2024",
    rating: 5,
    country: "Indonesia",
  },
  {
    title: "great app",
    text: "im an aspiring german learner and i found this app extremely helpful!",
    username: "byyungg",
    date: "Sep 25, 2024",
    rating: 5,
    country: "United States",
  },
  {
    title: "Good app",
    text: "Very good app for learning german",
    username: "Bob4254mac",
    date: "Sep 24, 2024",
    rating: 5,
    country: "Sweden",
  },
  {
    title: "Odlicna",
    text: "Sviđa mi se korišćenje aplikacije za unapređenje mog znanja nemačkih reči i gramatike. Aplikacija detaljno obrađuje gramatičke teme kao što su predlozi i nemački članci 'der, die, das'.",
    username: "AkrabDng",
    date: "Sep 23, 2024",
    rating: 5,
    country: "Bosnia and Herzegovina",
  },
  {
    title: "Eficient pentru învățarea vocabularului german",
    text: "Învăț vocabularul german mult mai eficient decât înainte. Îmi plac tabelele concise de rezumat al gramaticii.",
    username: "Frizles66",
    date: "Sep 23, 2024",
    rating: 5,
    country: "Romania",
  },
  {
    title: "Replace your German course with this app",
    text: "I love Duolingo, don’t get me wrong. But this app does what other language apps don’t. It provides German learning in an easy, intuitive manner that can replace a school course (which Duolingo can’t). Personally, when I learn a language, I need to practice verb conjugations. This app provides a gamified solution to conjugation practice and I love it! Thank you guys!",
    username: "Misteryio101",
    date: "Sep 22, 2024",
    rating: 5,
    country: "United States",
  },
  {
    title: "The best german learning app out there",
    text: "I really love the simplicity and the vast array of language learning tools available.",
    username: "Arafaysaleem",
    date: "Sep 22, 2024",
    rating: 5,
    country: "United States",
  },
  {
    title: "Jako korisna aplikacija",
    text: "Veoma korisna aplikacija, posebno flash cards, mnogo su bolje nego Anki app. Zanimljiv koncept ucenja gdje imas dobar pregled cjeline koju zatim mozes vjezbati kroz razne vjezbice.",
    username: "_capoeira_",
    date: "Sep 21, 2024",
    rating: 5,
    country: "Bosnia and Herzegovina",
  },
  {
    title: "Neeeeat",
    text: "The flash card is exactly what I need for language learning!",
    username: "Jjack2685",
    date: "Sep 21, 2024",
    rating: 5,
    country: "United States",
  },
  {
    title: "Spatial Repetition Learning!",
    text: "Great app to help brush up on my German using Anki Style cards. The tts is helpful getting the pronunciation right also!",
    username: "Lgcfrvkitdvk",
    date: "Sep 21, 2024",
    rating: 5,
    country: "United States",
  },
  {
    title: "Great app for learning German.",
    text: "Learning through virtual flashcards is an excellent way to reinforce knowledge!",
    username: "Dr.bloom",
    date: "Sep 20, 2024",
    rating: 5,
    country: "United States",
  },
  {
    title: "#1 app to learn German",
    text: "Linguico is a fun and effective app for learning German through engaging flashcards and interactive games. It makes language learning accessible, combining useful vocabulary with an enjoyable experience. Perfect for learners of all levels! I also love that it’s free and you don’t need to make an account!",
    username: "Jefficthepic",
    date: "Sep 20, 2024",
    rating: 5,
    country: "United States",
  },
  {
    title: "Odlična app",
    text: "Super aplikacija za učenje njemačkog uz vježbe gramatike",
    username: "domagor",
    date: "Sep 20, 2024",
    rating: 5,
    country: "Croatia",
  },
  {
    title: "Ausgezeichnet",
    text: "Sehr hilfreich, um den deutschen Wortschatz zu erweitern und die Kenntnisse von der, die, das zu verbessern. Wirklich die beste App für 'der die das', da die App Hinweise zeigt, welcher Artikel korrekt ist.",
    username: "Sarfraz_",
    date: "Sep 18, 2024",
    rating: 5,
    country: "Germany",
  },
  {
    title: "Great app!: very helpful and straight forward",
    text: "This is actually a really helpful application for a few reasons! And specifically for me as someone who doesn’t speak German, but has an interest in learning it, that it seems like there are patterns and similarities between English and German that should be easy to get, but it’s still very hard for me. I like how this really does lay it out there plain and simple, and in a way that actually does stick. This is the way I learn best. Thanks!!",
    username: "riv3r1andstr3ams",
    date: "Sep 18, 2024",
    rating: 5,
    country: "United States",
  },
  {
    title: "Great app for German lessons",
    text: "As someone who's always wanted to learn German but struggled with traditional methods, this app has been a revelation. The 15,000-word vocabulary feature is impressive, covering everything from beginner to advanced levels. What I really appreciate is the focus on practical, commonly used words - it feels like I'm learning German I can actually use.",
    username: "M84rtin",
    date: "Sep 17, 2024",
    rating: 5,
    country: "Norway",
  },
  {
    title: "Good",
    text: "It makes my learning German easier.",
    username: "quanlvvn",
    date: "Sep 17, 2024",
    rating: 5,
    country: "Vietnam",
  },
  {
    title: "Very good!",
    text: "The app works well for what it's intended to do, easy to learn new words, rules and phrases!",
    username: "jxnata",
    date: "Sep 16, 2024",
    rating: 5,
    country: "Brazil",
  },
  {
    title: "Best app",
    text: "A game changer for language learners application.",
    username: "imTonyStark",
    date: "Sep 16, 2024",
    rating: 5,
    country: "India",
  },
  {
    title: "Helpful app for learning a new language",
    text: "Linguistic offers some really helpful tools to learn a new language. It also has a very clean and reactive experience :)",
    username: "JaceS23",
    date: "Sep 14, 2024",
    rating: 5,
    country: "United Kingdom",
  },
  {
    title: "Practical way to support my German learning",
    text: "I’ve been learning German mostly with Assimil and it can be confusing at times. This app allows me to quickly refer to specific cases and grammar, and test my memory of them. Very helpful as a reference and review tool!",
    username: "Léomorpho",
    date: "Sep 14, 2024",
    rating: 5,
    country: "Canada",
  },
  {
    title: "Great app",
    text: "It’s the best app for learning German I have found, other apps don’t focus on the specifics of the language as much. It has great tutorials and practice lessons that you won’t find in other apps.",
    username: "Daniella GF",
    date: "Sep 14, 2024",
    rating: 5,
    country: "United States",
  },
  {
    title: "Nice app for German learner",
    text: "The app offers nice and minimal features for learning German words and improving German grammar through various exercises which are very convenient for the beginner like me.",
    username: "Ben.Kit",
    date: "Sep 13, 2024",
    rating: 5,
    country: "Thailand",
  },
  {
    title: "Love this app!",
    text: "I use the app to learn German. I learn German words very fast through the flash cards that include grammar details, highly recommend this app!",
    username: "de_Pigeon",
    date: "Sep 13, 2024",
    rating: 5,
    country: "United Kingdom",
  },
  {
    title: "Amazing, Quick, and Easy!!!",
    text: "Excellent app for learning German. High quality flash cards for learning vocabulary and plenty of grammar exercises. Grammar summaries are perfect for reading during commutes.",
    username: "michxnam",
    date: "Sep 12, 2024",
    rating: 5,
    country: "United States",
  },
  {
    title: "Effective and User-Friendly German Learning App",
    text: "This app is great for learning German. The lessons are clear, and the practice exercises are really helpful. I’m noticing progress every day. It’s been a great tool for building my vocabulary and understanding grammar.",
    username: "luximetr.sync",
    date: "Sep 12, 2024",
    rating: 5,
    country: "Singapore",
  },
  {
    title: "It just works",
    text: "Very simple app, easy to use. I wanted to refresh my German I learned a long time ago and I found it helpful.",
    username: "Passatgt",
    date: "Sep 12, 2024",
    rating: 5,
    country: "Hungary",
  },
  {
    title: "simple and easy to use",
    text: "Loved the app it’s really great",
    username: "wayfarer2503",
    date: "Sep 11, 2024",
    rating: 5,
    country: "India",
  },
  {
    title: "App para aprender alemán perfecta",
    text: "Fabulosa App para aprender alemán!! Es una herramienta excelente para quienes buscan aprender y ampliar vocabulario en alemán. Las listas de palabras están bien organizadas por temas, lo que hace que el aprendizaje sea sencillo y efectivo. Es una aplicación simple y práctica, ideal para quienes buscan mejorar su alemán de manera rápida y enfocada. Muy recomendable!!",
    username: "AitorGR8",
    date: "Sep 8, 2024",
    rating: 5,
    country: "Spain",
  },
  {
    title: "Bardzo dobra apka do nauki języka niemieckiego",
    text: "Linguistic pomogła mi nauce języka niemieckiego",
    username: "localherodiscord",
    date: "Sep 8, 2024",
    rating: 5,
    country: "Poland",
  },
  {
    title: "This has been helpful",
    text: "I have really enjoyed using the interface of this app, it has been very easy to use and fun so far",
    username: "Rj212Rj",
    date: "Sep 8, 2024",
    rating: 5,
    country: "United States",
  },
  {
    title: "Nice app to learn german!",
    text: "I was testing the app and it’s really great! Good lessons, nice design, great learning experience!",
    username: "Paulkz",
    date: "Sep 6, 2024",
    rating: 5,
    country: "Germany",
  },
  {
    title: "Great App to hone your German skills",
    text: "This App does not only focus on the vocabulary of the language, it also has focus on the articles, prepositions and cases. Can recommend.",
    username: "BigBart81",
    date: "Sep 6, 2024",
    rating: 5,
    country: "Switzerland",
  },
  {
    title: "L’appli parfaite pour apprendre l’allemand!",
    text: "Mon allemand était un peu rouillé et j’ai décidé de m’y remettre sérieusement, grâce à cette appli je suis en très bon chemin pour maitriser cette langue ô combien compliquée. Il y a tous les outils, les cartes de révisions et les tests sont superbes et en plus j’adore l’esthétique!",
    username: "Mr. Laed?",
    date: "Sep 6, 2024",
    rating: 5,
    country: "France",
  },
  {
    title: "Great way to learn German",
    text: "I have recently started learning German and I have found this way to be very easy to get started and it did improve my German words vocabulary by a lot.",
    username: "Viktorrtt",
    date: "Sep 5, 2024",
    rating: 5,
    country: "United States",
  },
  {
    title: "Great app for learning German language",
    text: "Waiting for new language options :)",
    username: "Bohdan Kolesnyk",
    date: "Sep 5, 2024",
    rating: 5,
    country: "Ukraine",
  },
  {
    title: "Awesome",
    text: "A good workout and an amazing dictionary.",
    username: "manta1900",
    date: "Sep 5, 2024",
    rating: 5,
    country: "Greece",
  },
  {
    title: "And suddenly I'm learning German",
    text: "Stumbled upon this app with no real motivation to learn German, but a few days later I can speak full sentences!",
    username: "krs.tphr",
    date: "Sep 4, 2024",
    rating: 5,
    country: "Canada",
  },
  {
    title: "Great Practice",
    text: "Great app to get some quick practice in - with helpful hints for each word too.",
    username: "awesome5185",
    date: "Sep 3, 2024",
    rating: 5,
    country: "Australia",
  },
  {
    title: "Cool and useful app",
    text: "German is really hard and I wanted to pick up a few words as I’m traveling there in a few months. The app does what it says it does and makes learning German easy and simple!",
    username: "Mggdhhfrg",
    date: "Sep 3, 2024",
    rating: 5,
    country: "United States",
  },
  {
    title: "Hilfreich",
    text: "Liefert vieles was das Sprachverständis fördert. Wichtige Grammatik immer auf Abruf!",
    username: "cmmntby",
    date: "Sep 2, 2024",
    rating: 5,
    country: "Germany",
  },
  {
    title: "Effective and User-Friendly German Learning App",
    text: "This app is great for learning German. The lessons are clear, and the practice exercises are really helpful. I’m noticing progress every day. It’s been a great tool for building my vocabulary and understanding grammar.",
    username: "luximetr.sync",
    date: "Sep 12, 2024",
    rating: 5,
    country: "Singapore",
  },
  {
    title: "It just works",
    text: "Very simple app, easy to use. I wanted to refresh my German I learned a long time ago and I found it helpful.",
    username: "Passatgt",
    date: "Sep 12, 2024",
    rating: 5,
    country: "Hungary",
  },
  {
    title: "simple and easy to use",
    text: "Loved the app it’s really great",
    username: "wayfarer2503",
    date: "Sep 11, 2024",
    rating: 5,
    country: "India",
  },
  {
    title: "App para aprender alemán perfecta",
    text: "Fabulosa App para aprender alemán!! Es una herramienta excelente para quienes buscan aprender y ampliar vocabulario en alemán. Las listas de palabras están bien organizadas por temas, lo que hace que el aprendizaje sea sencillo y efectivo. Es una aplicación simple y práctica, ideal para quienes buscan mejorar su alemán de manera rápida y enfocada. Muy recomendable!!",
    username: "AitorGR8",
    date: "Sep 8, 2024",
    rating: 5,
    country: "Spain",
  },
  {
    title: "Bardzo dobra apka do nauki języka niemieckiego",
    text: "Linguistic pomogła mi nauce języka niemieckiego",
    username: "localherodiscord",
    date: "Sep 8, 2024",
    rating: 5,
    country: "Poland",
  },
  {
    title: "This has been helpful",
    text: "I have really enjoyed using the interface of this app, it has been very easy to use and fun so far",
    username: "Rj212Rj",
    date: "Sep 8, 2024",
    rating: 5,
    country: "United States",
  },
  {
    title: "Nice app to learn german!",
    text: "I was testing the app and it’s really great! Good lessons, nice design, great learning experience!",
    username: "Paulkz",
    date: "Sep 6, 2024",
    rating: 5,
    country: "Germany",
  },
  {
    title: "Great App to hone your German skills",
    text: "This App does not only focus on the vocabulary of the language, it also has focus on the articles, prepositions and cases. Can recommend.",
    username: "BigBart81",
    date: "Sep 6, 2024",
    rating: 5,
    country: "Switzerland",
  },
  {
    title: "L’appli parfaite pour apprendre l’allemand!",
    text: "Mon allemand était un peu rouillé et j’ai décidé de m’y remettre sérieusement, grâce à cette appli je suis en très bon chemin pour maitriser cette langue ô combien compliquée. Il y a tous les outils, les cartes de révisions et les tests sont superbes et en plus j’adore l’esthétique!",
    username: "Mr. Laed?",
    date: "Sep 6, 2024",
    rating: 5,
    country: "France",
  },
  {
    title: "Great way to learn German",
    text: "I have recently started learning German and I have found this way to be very easy to get started and it did improve my German words vocabulary by a lot.",
    username: "Viktorrtt",
    date: "Sep 5, 2024",
    rating: 5,
    country: "United States",
  },
  {
    title: "Great app for learning German language",
    text: "Waiting for new language options :)",
    username: "Bohdan Kolesnyk",
    date: "Sep 5, 2024",
    rating: 5,
    country: "Ukraine",
  },
  {
    title: "Awesome",
    text: "A good workout and an amazing dictionary.",
    username: "manta1900",
    date: "Sep 5, 2024",
    rating: 5,
    country: "Greece",
  },
  {
    title: "And suddenly I'm learning German",
    text: "Stumbled upon this app with no real motivation to learn German, but a few days later I can speak full sentences!",
    username: "krs.tphr",
    date: "Sep 4, 2024",
    rating: 5,
    country: "Canada",
  },
  {
    title: "Great Practice",
    text: "Great app to get some quick practice in - with helpful hints for each word too.",
    username: "awesome5185",
    date: "Sep 3, 2024",
    rating: 5,
    country: "Australia",
  },
  {
    title: "Cool and useful app",
    text: "German is really hard and I wanted to pick up a few words as I’m traveling there in a few months. The app does what it says it does and makes learning German easy and simple!",
    username: "Mggdhhfrg",
    date: "Sep 3, 2024",
    rating: 5,
    country: "United States",
  },
  {
    title: "Hilfreich",
    text: "Liefert vieles was das Sprachverständis fördert. Wichtige Grammatik immer auf Abruf!",
    username: "cmmntby",
    date: "Sep 2, 2024",
    rating: 5,
    country: "Germany",
  },
  {
    title: "Best app for learning German vocabulary",
    text: "Linguistic has been great for learning German words with flashcards. I am preparing for the Goethe exams and have significantly improved my vocabulary and understanding of German articles: der die das.",
    username: "Levi198803",
    date: "Sep 1, 2024",
    rating: 5,
    country: "Hong Kong",
  },
  {
    title: "Recommend for beginners and intermediate learners",
    text: "This is a fantastic app for mastering German. The lessons are easy to follow. The pronunciation guides are a great help in getting the accent right. Highly recommend for beginners and intermediate learners!",
    username: "KeshS200",
    date: "Sep 1, 2024",
    rating: 5,
    country: "Sri Lanka",
  },
  {
    title: "Finally, a way to learn German without distractions",
    text: "I’ve been scouring ways to learn German vocabulary without all the nonsense of upselling paid services. I’ve been practicing grammar to improve my conversation with German relatives, who are constantly correcting the gender articles. It’s been a godsend finding an app that lets me practice just that! Drilling the der, die, das is something I can now do in my downtime between meetings or on-the-go! The voice readout is also quite clear-sounding.",
    username: "Diana 🍰",
    date: "Aug 30, 2024",
    rating: 5,
    country: "United States",
  },
  {
    title: "Great app for learning German vocabulary",
    text: "Great flashcards for learning German vocabulary. Effective exercises for practicing the German articles der, die, das.",
    username: "..,.,.,.,,,.m.",
    date: "Aug 28, 2024",
    rating: 5,
    country: "United Kingdom",
  },
  {
    title: "Fantastica per studiare",
    text: "Ottima app per imparare le parole tedesche con i flashcard. Mi piacciono i dettagli sulle coniugazioni dei verbi inclusi nei flashcard e la rilevanza delle parole. Ci sono degli ottimi esercizi di grammatica e allenamenti per gli articoli “der die das”. È stata molto utile per i miei studi di tedesco.",
    username: "Lello9800",
    date: "Aug 28, 2024",
    rating: 5,
    country: "Italy",
  },
  {
    title: "Very useful!",
    text: "The best flashcards for learning German vocabulary and of much better quality than those in an Anki deck. Since the flashcards are ordered by frequency, you will only learn relevant German words. The der, die, das exercise with hints is really useful. The grammar summaries on prepositions are great and easy to read during my commute.",
    username: "Deeznutzinyamoufbiatch",
    date: "Aug 28, 2024",
    rating: 5,
    country: "United States",
  },
  {
    title: "Super helpful for learning German",
    text: "I love this app. I like the example sentences provided in the flashcards for learning German words. It also shows the verb conjugations and irregular endings. The best app for learning German vocabulary for both beginners and intermediate learners. Plenty of grammar exercises.",
    username: "gucci polish guy",
    date: "Aug 26, 2024",
    rating: 5,
    country: "Canada",
  },
  {
    title: "Vocab learning",
    text: "The flashcards for learning vocabulary consist of relevant words and are of very good quality. I like the grammar summary tables, the der, die, das exercise, and the prepositions exercise. The der, die, das exercise helped me to correctly memorize the German articles. The content of this app is far more in-depth and superior to those of other language learning apps.",
    username: "junaid5595",
    date: "Aug 25, 2024",
    rating: 5,
    country: "Pakistan",
  },
  {
    title: "so hilfsbereit",
    text: "Diese App ist so gut und hilfsbereit! Es hilft dir durch alles! Es ist toll zu üben!",
    username: "bumcrackjuice",
    date: "Aug 22, 2024",
    rating: 5,
    country: "United Kingdom",
  },
  {
    title: "Recommended",
    text: "Good app for learning German language, includes both grammar description as well as verbal practice, etc.",
    username: "Norskj",
    date: "Aug 22, 2024",
    rating: 5,
    country: "Switzerland",
  },
  {
    title: "Nice app",
    text: "I've been using Linguistic: Learn German for a while now, and it's been a great experience. The summary tables on grammar are really good and well-structured. I love how the app breaks down the language into manageable chunks, making it less overwhelming. The exercises are practical and help reinforce what I've learned. I also appreciate the audio features, which make it easier to practice pronunciation. Whether you're just starting out or looking to improve your skills, this app is a solid choice. Highly recommend it to anyone wanting to learn German!",
    username: "Mixx234",
    date: "Aug 21, 2024",
    rating: 5,
    country: "India",
  },
  {
    title: "Convenient reference guide!",
    text: "A great guide to have for travel or for anyone who’s trying to learn the basics of German. I like how everything is broken down into easy to follow sections!",
    username: "AvidLearner38",
    date: "Aug 21, 2024",
    rating: 5,
    country: "United States",
  },
  {
    title: "Amazing app!!",
    text: "I love the clean, minimalistic design! It's also super user-friendly and enjoyable to spend hours on, especially for vocabulary learning and reviewing grammar summaries. Overall, it's an excellent tool for German learners of all levels, from beginners to advanced.",
    username: "odifinlio",
    date: "Aug 17, 2024",
    rating: 5,
    country: "Canada",
  },
  {
    title: "Nice language app",
    text: "I’m currently learning German and this app helps me a lot to study this language and learn new vocabulary and improve my grammar.",
    username: "vathng",
    date: "Aug 16, 2024",
    rating: 5,
    country: "France",
  },
  {
    title: "Excellent app!",
    text: "Excellent app for learning and improving my German. Simple and intuitive interface that helps you to stay focused while studying. The app is much better for learning vocabulary with flash cards than the Anki App. I recommend it!",
    username: "Ewerton Wantroba",
    date: "Aug 14, 2024",
    rating: 5,
    country: "Brazil",
  },
  {
    title: "Love this app!",
    text: "Works great if you want to brush up before a trip!",
    username: "Shoppergamer",
    date: "Aug 13, 2024",
    rating: 5,
    country: "United States",
  },
  {
    title: "recommended",
    text: "My girlfriend uses it as she struggles with der die das.",
    username: "TBDaehler",
    date: "Aug 8, 2024",
    rating: 5,
    country: "Switzerland",
  },
  {
    title: "Comprehensive German Learning App",
    text: "I really liked the flashcards for refreshing my German vocabulary. The app also offers a wide range of grammar exercises, and I'm excited to see what new features the developer will add 😄.",
    username: "Dennis1802",
    date: "Aug 3, 2024",
    rating: 5,
    country: "Netherlands",
  },
];
